// Third party imports
import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyModal from "common/Components/ExlyModal";
import classes from "./TableFields.module.css";
import { InfoIcon } from "./InfoIconTooltip";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import constants from "../../constants";
import FilledTag from "assets/vectors/crm/filled_tag.svg";
import ExlyImage from "common/Components/ExlyImage";

export const DynamicSegmentsPopUp = ({ record, action_type }) => {
  const [showModal, setShowModal] = React.useState(false);
  const { isOnboarded, flagLoading, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({
      featureKey: onboardingFeatureKeys.SEGMENTS.DYNAMIC_SEGMENTATION,
    });

  React.useEffect(() => {
    if (flagLoading) return;

    if (!isOnboarded && action_type === constants.action_type_popover) {
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  }, [isOnboarded, flagLoading]);

  const is_desktop = useDesktopMediaQuery();

  const onClose = () => {
    setShowModal(false);
    handleFeatureOnboardChange(true);
  };

  if (record?.is_tag_embedded)
    return (
      <div className={classes.point}>
        <ExlyImage src={FilledTag} className={classes.tag} />

        {is_desktop ? null : <InfoIcon record={record} />}
      </div>
    );

  return (
    <>
      {action_type !== constants.action_type_popover && (
        <div className={classes.point}>
          {" "}
          <img
            alt="dynamic_segment"
            onClick={() => setShowModal(true)}
            src={require("assets/images/segments/desktop/dynamic_segment.svg")}
          />
          &emsp;
          {is_desktop ? null : <InfoIcon record={record} />}
        </div>
      )}

      <ExlyModal
        title={
          <>
            <div className={classes.modalHeader}>
              <span>Dynamic Segments</span>&nbsp;
              <img
                alt="dynamic_sparkle"
                src={require("assets/images/segments/desktop/dynamic_segments_sparkle_gradient.svg")}
              />{" "}
            </div>
          </>
        }
        mobile_modal_props={{
          secondaryAction: undefined,
        }}
        primaryBtnFullWidth={true}
        showSecondaryBtn={false}
        open={showModal}
        primaryBtnText="Okay, got it"
        onClose={() => onClose()}
        onPrimaryBtnClick={() => onClose()}
      >
        <div className={classes.modalContainer}>
          <h5>What is that?</h5>
          <p>
            We automatically update the conditions set for dynamic segments
            depending on your growth as a creator.
          </p>
        </div>
      </ExlyModal>
    </>
  );
};
