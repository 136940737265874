import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classes from "./CreateSegment.module.css";
import { RadioGroup } from "@my-scoot/component-library-legacy";
import { addRadioOptions } from "../constants";

const CreateSegment = ({ open, onClose, setTarget, handleSetConditions }) => {
  const is_desktop = useDesktopMediaQuery();

  //Handler to get the selected value for the type of segment user wants to create
  const handleChangeRadioGroup = (value) => {
    setTarget(value?.value);
  };

  return (
    //Modal to Create A New Segment
    <div>
      <ExlyModal
        title="Who are you segmenting for?"
        open={open}
        primaryBtnText="Next: Set conditions for segment"
        onClose={onClose}
        onPrimaryBtnClick={handleSetConditions}
        showSecondaryBtn={false}
        primaryBtnFullWidth
        secondaryBtnText={<></>}
        mobile_modal_props={{
          secondaryAction: !is_desktop && undefined,
        }}
      >
        <div className={classes.contentModal}>
          <p>
            {`Let us know who is your target for this segment, and we'll help you
            create and customize your segment accordingly.`}
          </p>

          <div className={classes.modalRadioGroup}>
            <RadioGroup
              options={addRadioOptions}
              selected={addRadioOptions[0]} // by default 1st item is selected
              onChange={(value) => handleChangeRadioGroup(value)}
            />
          </div>
        </div>
      </ExlyModal>
    </div>
  );
};

export default CreateSegment;
