// Third party imports
import React from "react";

import classes from "./TableFields.module.css";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { Tooltip, Button } from "@my-scoot/component-library-legacy";
import { is_desktop } from "utils/Utils";

export const InfoIcon = ({ record }) => {
  return (
    <div className={classes.flex}>
      {record?.title}
      {record?.description ? (
        <div className={classes.tooltipIcon}>
          <Tooltip
            color="secondary"
            title={
              <div className={classes.tooltipContainer}>
                <div>{record?.description}</div>

                {is_desktop ? null : (
                  <div className={classes.tooltipButtonContainer}>
                    <Button color="primary" className={classes.tooltipButton}>
                      <span>Got it</span>
                    </Button>
                  </div>
                )}
              </div>
            }
          >
            <HelpOutlineIcon className={classes.helpIcon} />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
