import laserIcon from "../../../../assets/images/segments/desktop/laser.svg";
import marketingIcon from "../../../../assets/images/segments/desktop/marketing.svg";
import serviceIcon from "../../../../assets/images/segments/desktop/better-service.svg";
import starterDesktop from "../../../../assets/images/segments/desktop/starter-desktop.svg";
import starterMobile from "../../../../assets/images/segments/mobile/starter-mobile.svg";
import GuideMobile01 from "../../../../assets/images/segments/mobile/guide_mobile_01.svg";
import GuideMobile02 from "../../../../assets/images/segments/mobile/guide_mobile_02.svg";
import GuideMobile03 from "../../../../assets/images/segments/mobile/guide_mobile_03.svg";
import GuideMobile04 from "../../../../assets/images/segments/mobile/guide_mobile_04.svg";
import GuideMobile05 from "../../../../assets/images/segments/mobile/guide_mobile_05.svg";

import GuideDesktop01 from "../../../../assets/images/segments/desktop/guide_desktop_01.svg";
import GuideDesktop02 from "../../../../assets/images/segments/desktop/guide_desktop_02.svg";
import GuideDesktop03 from "../../../../assets/images/segments/desktop/guide_desktop_03.svg";
import GuideDesktop04 from "../../../../assets/images/segments/desktop/guide_desktop_04.svg";
import GuideDesktop05 from "../../../../assets/images/segments/desktop/guide_desktop_05.svg";

import EditNameDesktop from "../../../../assets/images/segments/desktop/edit_segment_name_desktop.svg";
import EditNameMobile from "../../../../assets/images/segments/mobile/edit_segment_name_mobile.svg";

export const getSegmentationIntoSectionBrief = (isDesktop) => ({
  Icon: isDesktop ? starterDesktop : starterMobile,
  primaryTitle: "Personalize with",
  secondaryTitle: "Segementation",
  description:
    "You can now categorize your audience into segments based on shared behaviour, and drive a personalized approach for each group!",
});

export const getEditSegmentationNameBrief = (isDesktop) => ({
  Icon: isDesktop ? EditNameDesktop : EditNameMobile,
});

export const segmentationIntroSectionList = {
  primaryTitle: "Why even create",
  secondaryTitle: "Segments?",
  list: [
    {
      title: "Laser-focused approach",
      icon: laserIcon,
      description:
        "Focus on the most profitable or easiest to convert, rather than addressing the market as a whole.",
    },
    {
      title: "Effective marketing",
      icon: marketingIcon,
      description:
        "Different customers react differently to different messages. Shape your marketing messaging via segments.",
    },
    {
      title: "Deliver better service",
      icon: serviceIcon,
      description:
        "Personalized experience holds great power. Learn your audience and design how to best approach them.",
    },
  ],
};

export const getSegmentationGuideSlides = (isDesktop) => [
  {
    title: "Give us your pick",
    icon: isDesktop ? GuideDesktop01 : GuideMobile01,
    description:
      "We have a myriad of pre-set conditions ready for you to play around with. Just let us know who you are targetting, and we’ll present you with a bunch that best suits your needs.",
  },
  {
    title: "Add conditions & scenarios",
    icon: isDesktop ? GuideDesktop02 : GuideMobile02,
    description:
      "Setup conditions for your segment. The scenarios could be anything, from the city or country they belong to, to their booking and email activity.",
  },
  {
    title: "Keeping it simple",
    icon: isDesktop ? GuideDesktop03 : GuideMobile03,
    description:
      "All the conditions you add will be in only ‘AND’ or an ‘OR’ fashion. This means, that all the conditions you apply will be either ‘all applicable’ or ‘any one of them’ applicable.",
  },
  {
    title: "Your group is ready!",
    icon: isDesktop ? GuideDesktop04 : GuideMobile04,
    description:
      "While you add conditions to your segment, you can preview your segmented customer list as well. Once done with the segment, hit ‘Create’.",
  },
  {
    title: "Name your segment",
    icon: isDesktop ? GuideDesktop05 : GuideMobile05,
    description:
      "Every segment has a name that identifies with the type of audience it contains. Give your segment a name that describes your customers, and there you have your segment!",
  },
];

export const segmentLabels = {
  member_name: "Member Name",
  document_title: "Don’t know where to begin?",
  page_title: "Segments",
  page_description:
    "Below are a few segments we have already created for you. Try them out or create new ones!",
  create_segment: "Create a new segment",
  role_type: "Role Name",
  is_active: "Status",
  customer_count: "Customers",
  revenue: "Revenue",
  last_active_time: "Last active",
  contact: "Contact",
  role_filter: "Role",
  member_name_filter: "Name",
  email_filter: "Email",
  phone_number: "Phone",
  offering_name: "Offering Name",
  status_filter: "Status",
  offering_type: "Offering Type",
};

export default {
  why_create_segments: [
    {
      id: 0,
      heading: "Laser-focused approach",
      sub_heading:
        "Focus on the most profitable or easiest to convert, rather than addressing the market as a whole.",
      icon: "../../../../../assets/images/segments/desktop/laser.svg",
    },
    {
      id: 1,
      heading: "Effective marketing",
      sub_heading:
        "Different customers react differently to different messages. Shape your marketing messaging via segments.",
    },
    {
      id: 2,
      heading: "Deliver better service",
      sub_heading:
        "Personalized experience holds great power. Learn your audience and design how to best approach them.",
    },
  ],

  path: "host/segments/list",

  step_one_view: 1,
  step_two_view: 2,
  mobile_width: 767,

  enums_comp_condtions: {
    lt: "less than",
    ge: "equal to",
    lte: "less than or equal to",
    gte: "greater than or equal to",
    gt: "greater than",
    contains: "contains",
  },

  enums_comp_condtions_days: {
    lt: "before",
    gt: "after",
    lte: "on or before",
    gte: "on or after",
    exact: " ",
    range: "within the date range",
  },

  enums_reason_type: {
    0: "Listing Get in touch",
    1: "Get in touch",
    2: "Listing exiting intent",
    3: "Exit Intent",
    4: "Payment Failed",
    5: "Payment Pending",
    6: "Demo Payment",
    7: "Payment Done",
    8: "Imported Lead",
    9: "Blog Subscriber User",
  },

  action_type_popover: "action_type_popover",

  value_based_comparator: {
    date_range: " ~ ",
  },

  condition_value_types: {
    placeholder: "placeholder",
    now_days: "now_days",
    date_range: "date_range",
    date: "date",
    lead_reason_types: "lead_reason_types",
  },

  condition_values: {
    top_txn_count: "top_txn_count",
    top_avg_spend: "top_avg_spend",
    highest_ticket_listing: "highest_ticket_listing",
    second_highest_ticket_listing: "second_highest_ticket_listing",
  },

  condtion_fields: {
    first_occurrence_time: "first_occurrence_time",
  },

  lead_url: "/host/segments/list/leads",

  segment_type: {
    leads: "leads",
    customers: "customers",
  },

  target_route_params: "target",
  action: "action",

  toggle_and: "AND",
  toggle_or: "OR",

  offering_data: "offering",
  assignee_data: "assignee",
  offering_data_type: "listings",
  input_value_field: "Input",
  date_value_field: "Date",
  date_range_value_field: "Date Range",
  advance_filter: "advance_filter",
  feedback_rating: "feedback_rating",
  feedback_filter: "feedback_filter",
  coupon_applied: "coupon_applied",
  time_offer_applied: "time_offer_applied",
  select_offering: "individual offering",
  integer: "integer",
  status: "status",
  org_member: "org_member",
  lead_status: "lead_status",
  reason_type: "reason_type",
  boolean: "boolean",
  now_days: "now_days",
  is_within: "is within",
  equal_to: "equal to",
  date_value_type: "date",
  date_range_value_type: "date_range",
  transactions_count: "transactions_count",
  is_on_or_after: "is on or after",
  is_greater_than_or_equal_to: "is greater than or equal to",
  messageTableColumns: [
    {
      field: "display_name",
      headerName: "Name",
      isPrimary: true,
      emptyField: "N/A",
      columnClassName: "text-break",
      width: "42%",
    },
    {
      field: "email",
      headerName: "Email",
      emptyField: "N/A",
    },
    {
      field: "phone_number",
      headerName: "Phone",
      emptyField: "N/A",
    },
  ],
};

export const enums_component_type = {
  List: 1,
  Offering: 2,
  Input: 3,
};

export const enums_field_types = {
  Field: "field",
  Comparator: "comparator",
  Value: "value",
};

export const enums_combining_filter = {
  OR: 2,
  AND: 1,
};

export const enums_targets = {
  CUSTOMERS: "1",
  LEADS: "2",
};

export const enums_fields = {
  country: "Country",
  first_transaction_date: "Sign up date",
  last_transaction_date: "Last Transaction Date",
  offering_purchased: "Offering purchased",
  transactions_count: "Number of transactions",
  amount_spent: "Total amount spent",
  coupon_applied: "Discount Code",
  time_offer_applied: "Limited Time Offer",
  discount_applied: "Discount amount",
  feedback_rating: "Feedback",
  first_occurrence_time: "Capture date",
  updated_at: "Last updated at",
  status: "Lead status",
  reason_type: "Last Activity",
  org_member: "Assignee",
};

export const segment_comparator = {
  is: {
    comparator: "exact",
  },
  "is not": {
    comparator: "exact",
    exclude: true,
  },
  "is empty": {
    comparator: "isnull",
  },
  "is any of": {
    comparator: "in",
  },
  "is none of": {
    comparator: "in",
    exclude: true,
    view_filters: "none of",
  },
  "is on or before": {
    comparator: "lte",
  },
  "is before": {
    comparator: "lt",
  },
  "is after": {
    comparator: "gt",
  },
  "is on or after": {
    comparator: "gte",
  },
  "is equal to": {
    comparator: "exact",
    view_filters: "equal to",
  },
  "is not equal to": {
    comparator: "exact",
    exclude: true,
    view_filters: "not equal to",
  },
  "is greater than": {
    comparator: "gt",
    view_filters: "is greater than",
  },
  "is greater than or equal to": {
    comparator: "gte",
    view_filters: "is greater than or equal to",
  },
  "is less than": {
    comparator: "lt",
    view_filters: "is less than",
  },
  "is less than or equal to": {
    comparator: "lte",
    view_filters: "is less than or equal to",
  },
  "is within": {
    comparator: "gte",
    view_filters: "is within",
  },
  date_range: {
    comparator: "range",
    view_filters: "date_range",
  },
  "is applied to": {},
};

export const status_ids_map = {
  new_lead: 1,
  converted_lead: 2,
  no_response: 3,
  interested: 4,
  not_interested: 5,
};

export const reason_type_ids_map = {
  interested_in_an_offering: 0,
  payment_failed: 4,
  payment_initiated: 5,
  requested_to_be_contacted: 1,
  viewed_an_offering: 2,
  viewed_your_website: 3,
  imported_leads: 8,
};

// TODO :@Akash: advance filter constant needs to be fixed as in status and reason type.
export const advance_filter_value = {
  coupon_applied: {
    "all bookings": {
      sub_filter: [
        {
          field: "offer_count",
          value: 0,
          comparator: "gt",
          label: "all bookings",
        },
        {
          field: "offer_count",
          value: "payments_count",
          comparator: "exact",
          label: "all bookings",
        },
      ],
    },
    "any bookings": {
      sub_filter: [
        {
          field: "offer_count",
          value: 0,
          comparator: "gt",
          label: "any bookings",
        },
        {
          field: "offer_count",
          value: "payments_count",
          comparator: "lte",
          label: "any bookings",
        },
      ],
    },
    "no bookings": {
      sub_filter: [
        {
          field: "offer_count",
          value: 0,
          comparator: "exact",
          label: "no bookings",
        },
      ],
    },
    "individual offering": {
      sub_filter: [
        {
          field: "offer_count",
          value: 0,
          comparator: "gt",
          label: "individual offering",
        },
        {
          field: "offer_count",
          comparator: "lte",
          value: "payments_count",
          value_type: "listings",
          label: "individual offering",
        },
      ],
    },
  },
  time_offer_applied: {
    "all bookings": {
      sub_filter: [
        {
          field: "all_offer_count",
          value: 0,
          comparator: "gt",
          label: "all bookings",
        },
        {
          field: "all_offer_count",
          value: "all_payments_count",
          comparator: "exact",
          label: "all bookings",
        },
      ],
    },
    "any bookings": {
      sub_filter: [
        {
          field: "all_offer_count",
          value: 0,
          comparator: "gt",
          label: "any bookings",
        },
        {
          field: "all_offer_count",
          value: "all_payments_count",
          comparator: "lte",
          label: "any bookings",
        },
      ],
    },
    "no bookings": {
      sub_filter: [
        {
          field: "all_offer_count",
          value: 0,
          comparator: "exact",
          label: "no bookings",
        },
      ],
    },
    "individual offering": {
      sub_filter: [
        {
          field: "all_offer_count",
          value: 0,
          comparator: "gt",
          label: "individual bookings",
        },
        {
          field: "all_offer_count",
          value: "all_payments_count",
          comparator: "exact",
          value_type: "listings",
          label: "individual offering",
        },
      ],
    },
  },
  discount_applied: {
    "all bookings": {
      sub_filter: [
        {
          field: "all_discount_count",
          value: 0,
          comparator: "gt",
          label: "all bookings",
        },
        {
          field: "all_discount_count",
          value: "all_payments_count",
          comparator: "exact",
          label: "all bookings",
        },
      ],
    },
    "any bookings": {
      sub_filter: [
        {
          field: "all_discount_count",
          value: 0,
          comparator: "gt",
          label: "any bookings",
        },
        {
          field: "all_discount_count",
          value: "all_payments_count",
          comparator: "lte",
          label: "any bookings",
        },
      ],
    },
    "no bookings": {
      sub_filter: [
        {
          field: "all_discount_count",
          value: 0,
          comparator: "exact",
          label: "no bookings",
        },
      ],
    },
    "individual offering": {
      sub_filter: [
        {
          field: "all_discount_count",
          value: 0,
          comparator: "gt",
          label: "individual bookings",
        },
        {
          field: "all_discount_count",
          comparator: "lte",
          value: "all_payments_count",
          value_type: "listings",
          label: "individual offering",
        },
      ],
    },
  },
  feedback_rating: {
    "all offerings purchased": {
      sub_filter: [
        {
          field: "all_feedback_count",
          value: 0,
          comparator: "gt",
          label: "all offerings purchased",
        },
        {
          field: "all_feedback_count",
          value: "all_offering_purchased_count",
          comparator: "exact",
          label: "all offerings purchased",
        },
      ],
    },
    "any offering purchased": {
      sub_filter: [
        {
          field: "all_feedback_count",
          value: 0,
          comparator: "gt",
          label: "any offering purchased",
        },
        {
          field: "all_feedback_count",
          value: "all_offering_purchased_count",
          comparator: "lte",
          label: "any offering purchased",
        },
      ],
    },
    "individual offering": {
      sub_filter: [
        {
          field: "all_feedback_count",
          value: 0,
          comparator: "gt",
          label: "individual offering",
        },
      ],
    },
  },
  status: {
    [status_ids_map.new_lead]: {
      id: status_ids_map.new_lead,
      label: "New Lead",
      value: status_ids_map.new_lead,
    },
    [status_ids_map.converted_lead]: {
      id: status_ids_map.converted_lead,
      label: "Converted Lead",
      value: status_ids_map.converted_lead,
    },
    [status_ids_map.no_response]: {
      id: status_ids_map.no_response,
      label: "No Response",
      value: status_ids_map.no_response,
    },
    [status_ids_map.interested]: {
      id: status_ids_map.interested,
      label: "Interested",
      value: status_ids_map.interested,
    },
    [status_ids_map.not_interested]: {
      id: status_ids_map.not_interested,
      label: "Not Interested",
      value: status_ids_map.not_interested,
    },
  },
  reason_type: {
    [reason_type_ids_map.interested_in_an_offering]: {
      id: reason_type_ids_map.interested_in_an_offering,
      label: "Interested in an offering",
      value: reason_type_ids_map.interested_in_an_offering,
    },
    [reason_type_ids_map.payment_failed]: {
      id: reason_type_ids_map.payment_failed,
      label: "Payment Failed",
      value: reason_type_ids_map.payment_failed,
    },
    [reason_type_ids_map.payment_initiated]: {
      id: reason_type_ids_map.payment_initiated,
      label: "Payment Initiated",
      value: reason_type_ids_map.payment_initiated,
    },
    [reason_type_ids_map.requested_to_be_contacted]: {
      id: reason_type_ids_map.requested_to_be_contacted,
      label: "Requested to be contacted",
      value: reason_type_ids_map.requested_to_be_contacted,
    },
    [reason_type_ids_map.viewed_an_offering]: {
      id: reason_type_ids_map.viewed_an_offering,
      label: "Viewed an offering",
      value: reason_type_ids_map.viewed_an_offering,
    },
    [reason_type_ids_map.viewed_your_website]: {
      id: reason_type_ids_map.viewed_your_website,
      label: "Viewed your website",
      value: reason_type_ids_map.viewed_your_website,
    },
    [reason_type_ids_map.imported_leads]: {
      id: reason_type_ids_map.imported_leads,
      label: "Imported leads",
      value: reason_type_ids_map.imported_leads,
    },
  },
};

export const segment_data = {
  countries: [
    {
      id: 1,
      label: "Afghanistan",
      value: "Afghanistan",
    },
    {
      id: 2,
      label: "Albania",
      value: "Albania",
    },
    {
      id: 3,
      label: "Algeria",
      value: "Algeria",
    },
    {
      id: 4,
      label: "AmericanSamoa",
      value: "AmericanSamoa",
    },
    {
      id: 5,
      label: "Andorra",
      value: "Andorra",
    },
    {
      id: 6,
      label: "Angola",
      value: "Angola",
    },
    {
      id: 7,
      label: "Anguilla",
      value: "Anguilla",
    },
    {
      id: 8,
      label: "Antigua and Barbuda",
      value: "Antigua and Barbuda",
    },
    {
      id: 9,
      label: "Argentina",
      value: "Argentina",
    },
    {
      id: 10,
      label: "Armenia",
      value: "Armenia",
    },
    {
      id: 11,
      label: "Aruba",
      value: "Aruba",
    },
    {
      id: 12,
      label: "Australia",
      value: "Australia",
    },
    {
      id: 13,
      label: "Austria",
      value: "Austria",
    },
    {
      id: 14,
      label: "Azerbaijan",
      value: "Azerbaijan",
    },
    {
      id: 15,
      label: "Bahamas",
      value: "Bahamas",
    },
    {
      id: 16,
      label: "Bahrain",
      value: "Bahrain",
    },
    {
      id: 17,
      label: "Bangladesh",
      value: "Bangladesh",
    },
    {
      id: 18,
      label: "Barbados",
      value: "Barbados",
    },
    {
      id: 19,
      label: "Belarus",
      value: "Belarus",
    },
    {
      id: 20,
      label: "Belgium",
      value: "Belgium",
    },
    {
      id: 21,
      label: "Belize",
      value: "Belize",
    },
    {
      id: 22,
      label: "Benin",
      value: "Benin",
    },
    {
      id: 23,
      label: "Bermuda",
      value: "Bermuda",
    },
    {
      id: 24,
      label: "Bhutan",
      value: "Bhutan",
    },
    {
      id: 25,
      label: "Bolivia, Plurinational State of",
      value: "Bolivia, Plurinational State of",
    },
    {
      id: 26,
      label: "Bosnia and Herzegovina",
      value: "Bosnia and Herzegovina",
    },
    {
      id: 27,
      label: "Botswana",
      value: "Botswana",
    },
    {
      id: 28,
      label: "Brazil",
      value: "Brazil",
    },
    {
      id: 29,
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    {
      id: 30,
      label: "Brunei Darussalam",
      value: "Brunei Darussalam",
    },
    {
      id: 31,
      label: "Bulgaria",
      value: "Bulgaria",
    },
    {
      id: 32,
      label: "Burkina Faso",
      value: "Burkina Faso",
    },
    {
      id: 33,
      label: "Burundi",
      value: "Burundi",
    },
    {
      id: 34,
      label: "Cambodia",
      value: "Cambodia",
    },
    {
      id: 35,
      label: "Cameroon",
      value: "Cameroon",
    },
    {
      id: 36,
      label: "Canada",
      value: "Canada",
    },
    {
      id: 37,
      label: "Cape Verde",
      value: "Cape Verde",
    },
    {
      id: 38,
      label: "Cayman Islands",
      value: "Cayman Islands",
    },
    {
      id: 39,
      label: "Central African Republic",
      value: "Central African Republic",
    },
    {
      id: 40,
      label: "Chad",
      value: "Chad",
    },
    {
      id: 41,
      label: "Chile",
      value: "Chile",
    },
    {
      id: 42,
      label: "China",
      value: "China",
    },
    {
      id: 43,
      label: "Christmas Island",
      value: "Christmas Island",
    },
    {
      id: 44,
      label: "Cocos (Keeling) Islands",
      value: "Cocos (Keeling) Islands",
    },
    {
      id: 45,
      label: "Colombia",
      value: "Colombia",
    },
    {
      id: 46,
      label: "Comoros",
      value: "Comoros",
    },
    {
      id: 47,
      label: "Congo",
      value: "Congo",
    },
    {
      id: 48,
      label: "Congo, The Democratic Republic of the",
      value: "Congo, The Democratic Republic of the",
    },
    {
      id: 49,
      label: "Cook Islands",
      value: "Cook Islands",
    },
    {
      id: 50,
      label: "Costa Rica",
      value: "Costa Rica",
    },
    {
      id: 51,
      label: "Cote 'Ivoire",
      value: "Cote 'Ivoire",
    },
    {
      id: 52,
      label: "Croatia",
      value: "Croatia",
    },
    {
      id: 53,
      label: "Cuba",
      value: "Cuba",
    },
    {
      id: 54,
      label: "Cyprus",
      value: "Cyprus",
    },
    {
      id: 55,
      label: "Czech Republic",
      value: "Czech Republic",
    },
    {
      id: 56,
      label: "Denmark",
      value: "Denmark",
    },
    {
      id: 57,
      label: "Djibouti",
      value: "Djibouti",
    },
    {
      id: 58,
      label: "Dominica",
      value: "Dominica",
    },
    {
      id: 59,
      label: "Dominican Republic",
      value: "Dominican Republic",
    },
    {
      id: 60,
      label: "Ecuador",
      value: "Ecuador",
    },
    {
      id: 61,
      label: "Egypt",
      value: "Egypt",
    },
    {
      id: 62,
      label: "El Salvador",
      value: "El Salvador",
    },
    {
      id: 63,
      label: "Equatorial Guinea",
      value: "Equatorial Guinea",
    },
    {
      id: 64,
      label: "Eritrea",
      value: "Eritrea",
    },
    {
      id: 65,
      label: "Estonia",
      value: "Estonia",
    },
    {
      id: 66,
      label: "Ethiopia",
      value: "Ethiopia",
    },
    {
      id: 67,
      label: "Falkland Islands (Malvinas)",
      value: "Falkland Islands (Malvinas)",
    },
    {
      id: 68,
      label: "Faroe Islands",
      value: "Faroe Islands",
    },
    {
      id: 69,
      label: "Fiji",
      value: "Fiji",
    },
    {
      id: 70,
      label: "Finland",
      value: "Finland",
    },
    {
      id: 71,
      label: "France",
      value: "France",
    },
    {
      id: 72,
      label: "French Guiana",
      value: "French Guiana",
    },
    {
      id: 73,
      label: "French Polynesia",
      value: "French Polynesia",
    },
    {
      id: 74,
      label: "Gabon",
      value: "Gabon",
    },
    {
      id: 75,
      label: "Gambia",
      value: "Gambia",
    },
    {
      id: 76,
      label: "Georgia",
      value: "Georgia",
    },
    {
      id: 77,
      label: "Germany",
      value: "Germany",
    },
    {
      id: 78,
      label: "Ghana",
      value: "Ghana",
    },
    {
      id: 79,
      label: "Gibraltar",
      value: "Gibraltar",
    },
    {
      id: 80,
      label: "Greece",
      value: "Greece",
    },
    {
      id: 81,
      label: "Greenland",
      value: "Greenland",
    },
    {
      id: 82,
      label: "Grenada",
      value: "Grenada",
    },
    {
      id: 83,
      label: "Guadeloupe",
      value: "Guadeloupe",
    },
    {
      id: 84,
      label: "Guam",
      value: "Guam",
    },
    {
      id: 85,
      label: "Guatemala",
      value: "Guatemala",
    },
    {
      id: 86,
      label: "Guernsey",
      value: "Guernsey",
    },
    {
      id: 87,
      label: "Guinea",
      value: "Guinea",
    },
    {
      id: 88,
      label: "Guinea-Bissau",
      value: "Guinea-Bissau",
    },
    {
      id: 89,
      label: "Guyana",
      value: "Guyana",
    },
    {
      id: 90,
      label: "Haiti",
      value: "Haiti",
    },
    {
      id: 91,
      label: "Holy See (Vatican City State)",
      value: "Holy See (Vatican City State)",
    },
    {
      id: 92,
      label: "Honduras",
      value: "Honduras",
    },
    {
      id: 93,
      label: "Hong Kong",
      value: "Hong Kong",
    },
    {
      id: 94,
      label: "Hungary",
      value: "Hungary",
    },
    {
      id: 95,
      label: "Iceland",
      value: "Iceland",
    },
    {
      id: 96,
      label: "India",
      value: "India",
    },
    {
      id: 97,
      label: "Indonesia",
      value: "Indonesia",
    },
    {
      id: 98,
      label: "Iran, Islamic Republic of",
      value: "Iran, Islamic Republic of",
    },
    {
      id: 99,
      label: "Iraq",
      value: "Iraq",
    },
    {
      id: 100,
      label: "Ireland",
      value: "Ireland",
    },
    {
      id: 101,
      label: "Isle of Man",
      value: "Isle of Man",
    },
    {
      id: 102,
      label: "Israel",
      value: "Israel",
    },
    {
      id: 103,
      label: "Italy",
      value: "Italy",
    },
    {
      id: 104,
      label: "Jamaica",
      value: "Jamaica",
    },
    {
      id: 105,
      label: "Japan",
      value: "Japan",
    },
    {
      id: 106,
      label: "Jersey",
      value: "Jersey",
    },
    {
      id: 107,
      label: "Jordan",
      value: "Jordan",
    },
    {
      id: 108,
      label: "Kazakhstan",
      value: "Kazakhstan",
    },
    {
      id: 109,
      label: "Kenya",
      value: "Kenya",
    },
    {
      id: 110,
      label: "Kiribati",
      value: "Kiribati",
    },
    {
      id: 111,
      label: "Korea, Democratic People's Republic of",
      value: "Korea, Democratic People's Republic of",
    },
    {
      id: 112,
      label: "Korea, Republic of",
      value: "Korea, Republic of",
    },
    {
      id: 113,
      label: "Kuwait",
      value: "Kuwait",
    },
    {
      id: 114,
      label: "Kyrgyzstan",
      value: "Kyrgyzstan",
    },
    {
      id: 115,
      label: "Lao People's Democratic Republic",
      value: "Lao People's Democratic Republic",
    },
    {
      id: 116,
      label: "Latvia",
      value: "Latvia",
    },
    {
      id: 117,
      label: "Lebanon",
      value: "Lebanon",
    },
    {
      id: 118,
      label: "Lesotho",
      value: "Lesotho",
    },
    {
      id: 119,
      label: "Liberia",
      value: "Liberia",
    },
    {
      id: 120,
      label: "Libyan Arab Jamahiriya",
      value: "Libyan Arab Jamahiriya",
    },
    {
      id: 121,
      label: "Liechtenstein",
      value: "Liechtenstein",
    },
    {
      id: 122,
      label: "Lithuania",
      value: "Lithuania",
    },
    {
      id: 123,
      label: "Luxembourg",
      value: "Luxembourg",
    },
    {
      id: 124,
      label: "Macao",
      value: "Macao",
    },
    {
      id: 125,
      label: "Macedonia, The Former Yugoslav Republic of",
      value: "Macedonia, The Former Yugoslav Republic of",
    },
    {
      id: 126,
      label: "Madagascar",
      value: "Madagascar",
    },
    {
      id: 127,
      label: "Malawi",
      value: "Malawi",
    },
    {
      id: 128,
      label: "Malaysia",
      value: "Malaysia",
    },
    {
      id: 129,
      label: "Maldives",
      value: "Maldives",
    },
    {
      id: 130,
      label: "Mali",
      value: "Mali",
    },
    {
      id: 131,
      label: "Malta",
      value: "Malta",
    },
    {
      id: 132,
      label: "Marshall Islands",
      value: "Marshall Islands",
    },
    {
      id: 133,
      label: "Martinique",
      value: "Martinique",
    },
    {
      id: 134,
      label: "Mauritania",
      value: "Mauritania",
    },
    {
      id: 135,
      label: "Mauritius",
      value: "Mauritius",
    },
    {
      id: 136,
      label: "Mayotte",
      value: "Mayotte",
    },
    {
      id: 137,
      label: "Mexico",
      value: "Mexico",
    },
    {
      id: 138,
      label: "Micronesia, Federated States of",
      value: "Micronesia, Federated States of",
    },
    {
      id: 139,
      label: "Moldova, Republic of",
      value: "Moldova, Republic of",
    },
    {
      id: 140,
      label: "Monaco",
      value: "Monaco",
    },
    {
      id: 141,
      label: "Mongolia",
      value: "Mongolia",
    },
    {
      id: 142,
      label: "Montenegro",
      value: "Montenegro",
    },
    {
      id: 143,
      label: "Montserrat",
      value: "Montserrat",
    },
    {
      id: 144,
      label: "Morocco",
      value: "Morocco",
    },
    {
      id: 145,
      label: "Mozambique",
      value: "Mozambique",
    },
    {
      id: 146,
      label: "Myanmar",
      value: "Myanmar",
    },
    {
      id: 147,
      label: "Namibia",
      value: "Namibia",
    },
    {
      id: 148,
      label: "Nauru",
      value: "Nauru",
    },
    {
      id: 149,
      label: "Nepal",
      value: "Nepal",
    },
    {
      id: 150,
      label: "Netherlands",
      value: "Netherlands",
    },
    {
      id: 151,
      label: "Netherlands Antilles",
      value: "Netherlands Antilles",
    },
    {
      id: 152,
      label: "New Caledonia",
      value: "New Caledonia",
    },
    {
      id: 153,
      label: "New Zealand",
      value: "New Zealand",
    },
    {
      id: 154,
      label: "Nicaragua",
      value: "Nicaragua",
    },
    {
      id: 155,
      label: "Niger",
      value: "Niger",
    },
    {
      id: 156,
      label: "Nigeria",
      value: "Nigeria",
    },
    {
      id: 157,
      label: "Niue",
      value: "Niue",
    },
    {
      id: 158,
      label: "Norfolk Island",
      value: "Norfolk Island",
    },
    {
      id: 159,
      label: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
    },
    {
      id: 160,
      label: "Norway",
      value: "Norway",
    },
    {
      id: 161,
      label: "Oman",
      value: "Oman",
    },
    {
      id: 162,
      label: "Pakistan",
      value: "Pakistan",
    },
    {
      id: 163,
      label: "Palau",
      value: "Palau",
    },
    {
      id: 164,
      label: "Palestinian Territory, Occupied",
      value: "Palestinian Territory, Occupied",
    },
    {
      id: 165,
      label: "Panama",
      value: "Panama",
    },
    {
      id: 166,
      label: "Papua New Guinea",
      value: "Papua New Guinea",
    },
    {
      id: 167,
      label: "Paraguay",
      value: "Paraguay",
    },
    {
      id: 168,
      label: "Peru",
      value: "Peru",
    },
    {
      id: 169,
      label: "Philippines",
      value: "Philippines",
    },
    {
      id: 170,
      label: "Pitcairn",
      value: "Pitcairn",
    },
    {
      id: 171,
      label: "Poland",
      value: "Poland",
    },
    {
      id: 172,
      label: "Portugal",
      value: "Portugal",
    },
    {
      id: 173,
      label: "Puerto Rico",
      value: "Puerto Rico",
    },
    {
      id: 174,
      label: "Qatar",
      value: "Qatar",
    },
    {
      id: 175,
      label: "Romania",
      value: "Romania",
    },
    {
      id: 176,
      label: "Russia",
      value: "Russia",
    },
    {
      id: 177,
      label: "Rwanda",
      value: "Rwanda",
    },
    {
      id: 178,
      label: "R\u00e9union",
      value: "R\u00e9union",
    },
    {
      id: 179,
      label: "Saint Barth\u00e9lemy",
      value: "Saint Barth\u00e9lemy",
    },
    {
      id: 180,
      label: "Saint Helena, Ascension and Tristan Da Cunha",
      value: "Saint Helena, Ascension and Tristan Da Cunha",
    },
    {
      id: 181,
      label: "Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis",
    },
    {
      id: 182,
      label: "Saint Lucia",
      value: "Saint Lucia",
    },
    {
      id: 183,
      label: "Saint Martin",
      value: "Saint Martin",
    },
    {
      id: 184,
      label: "Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon",
    },
    {
      id: 185,
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    {
      id: 186,
      label: "Samoa",
      value: "Samoa",
    },
    {
      id: 187,
      label: "San Marino",
      value: "San Marino",
    },
    {
      id: 188,
      label: "Sao Tome and Principe",
      value: "Sao Tome and Principe",
    },
    {
      id: 189,
      label: "Saudi Arabia",
      value: "Saudi Arabia",
    },
    {
      id: 190,
      label: "Senegal",
      value: "Senegal",
    },
    {
      id: 191,
      label: "Serbia",
      value: "Serbia",
    },
    {
      id: 192,
      label: "Seychelles",
      value: "Seychelles",
    },
    {
      id: 193,
      label: "Sierra Leone",
      value: "Sierra Leone",
    },
    {
      id: 194,
      label: "Singapore",
      value: "Singapore",
    },
    {
      id: 195,
      label: "Slovakia",
      value: "Slovakia",
    },
    {
      id: 196,
      label: "Slovenia",
      value: "Slovenia",
    },
    {
      id: 197,
      label: "Solomon Islands",
      value: "Solomon Islands",
    },
    {
      id: 198,
      label: "Somalia",
      value: "Somalia",
    },
    {
      id: 199,
      label: "South Africa",
      value: "South Africa",
    },
    {
      id: 200,
      label: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
    },
    {
      id: 201,
      label: "Spain",
      value: "Spain",
    },
    {
      id: 202,
      label: "Sri Lanka",
      value: "Sri Lanka",
    },
    {
      id: 203,
      label: "Sudan",
      value: "Sudan",
    },
    {
      id: 204,
      label: "Suriname",
      value: "Suriname",
    },
    {
      id: 205,
      label: "Svalbard and Jan Mayen",
      value: "Svalbard and Jan Mayen",
    },
    {
      id: 206,
      label: "Swaziland",
      value: "Swaziland",
    },
    {
      id: 207,
      label: "Sweden",
      value: "Sweden",
    },
    {
      id: 208,
      label: "Switzerland",
      value: "Switzerland",
    },
    {
      id: 209,
      label: "Syrian Arab Republic",
      value: "Syrian Arab Republic",
    },
    {
      id: 210,
      label: "Taiwan, Province of China",
      value: "Taiwan, Province of China",
    },
    {
      id: 211,
      label: "Tajikistan",
      value: "Tajikistan",
    },
    {
      id: 212,
      label: "Tanzania, United Republic of",
      value: "Tanzania, United Republic of",
    },
    {
      id: 213,
      label: "Thailand",
      value: "Thailand",
    },
    {
      id: 214,
      label: "Timor-Leste",
      value: "Timor-Leste",
    },
    {
      id: 215,
      label: "Togo",
      value: "Togo",
    },
    {
      id: 216,
      label: "Tokelau",
      value: "Tokelau",
    },
    {
      id: 217,
      label: "Tonga",
      value: "Tonga",
    },
    {
      id: 218,
      label: "Trinidad and Tobago",
      value: "Trinidad and Tobago",
    },
    {
      id: 219,
      label: "Tunisia",
      value: "Tunisia",
    },
    {
      id: 220,
      label: "Turkey",
      value: "Turkey",
    },
    {
      id: 221,
      label: "Turkmenistan",
      value: "Turkmenistan",
    },
    {
      id: 222,
      label: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
    },
    {
      id: 223,
      label: "Tuvalu",
      value: "Tuvalu",
    },
    {
      id: 224,
      label: "Uganda",
      value: "Uganda",
    },
    {
      id: 225,
      label: "Ukraine",
      value: "Ukraine",
    },
    {
      id: 226,
      label: "United Arab Emirates",
      value: "United Arab Emirates",
    },
    {
      id: 227,
      label: "United Kingdom",
      value: "United Kingdom",
    },
    {
      id: 228,
      label: "United States",
      value: "United States",
    },
    {
      id: 229,
      label: "Uruguay",
      value: "Uruguay",
    },
    {
      id: 230,
      label: "Uzbekistan",
      value: "Uzbekistan",
    },
    {
      id: 231,
      label: "Vanuatu",
      value: "Vanuatu",
    },
    {
      id: 232,
      label: "Venezuela, Bolivarian Republic of",
      value: "Venezuela, Bolivarian Republic of",
    },
    {
      id: 233,
      label: "Viet Nam",
      value: "Viet Nam",
    },
    {
      id: 234,
      label: "Virgin Islands, British",
      value: "Virgin Islands, British",
    },
    {
      id: 235,
      label: "Virgin Islands, U.S.",
      value: "Virgin Islands, U.S.",
    },
    {
      id: 236,
      label: "Wallis and Futuna",
      value: "Wallis and Futuna",
    },
    {
      id: 237,
      label: "Yemen",
      value: "Yemen",
    },
    {
      id: 238,
      label: "Zambia",
      value: "Zambia",
    },
    {
      id: 239,
      label: "Zimbabwe",
      value: "Zimbabwe",
    },
  ],
  boolean: [
    { id: 1, value: true, label: "Yes" },
    { id: 1, value: false, label: "No" },
  ],
  bookings: [
    {
      id: 1,
      label: "all bookings",
      value: "all bookings",
    },
    {
      id: 2,
      label: "any bookings",
      value: "any bookings",
    },
    {
      id: 3,
      label: "no bookings",
      value: "no bookings",
    },
    {
      id: 4,
      label: "individual offering",
      value: "individual offering",
    },
  ],
  coupons: [
    {
      id: 1,
      label: "all bookings",
      value: "all bookings",
    },
    {
      id: 2,
      label: "any bookings",
      value: "any bookings",
    },
    {
      id: 3,
      label: "no bookings",
      value: "no bookings",
    },
    {
      id: 4,
      label: "individual offering",
      value: "individual offering",
    },
  ],
  discounts: [
    {
      id: 1,
      label: "all bookings",
      value: "all bookings",
    },
    {
      id: 2,
      label: "any bookings",
      value: "any bookings",
    },
    {
      id: 3,
      label: "no bookings",
      value: "no bookings",
    },
    {
      id: 4,
      label: "individual offering",
      value: "individual offering",
    },
  ],
  feedbacks: [
    {
      id: 1,
      label: "all offerings purchased",
      value: "all offerings purchased",
    },
    {
      id: 2,
      label: "any offering purchased",
      value: "any offering purchased",
    },
    {
      id: 3,
      label: "individual offering",
      value: "individual offering",
    },
  ],
  date_value_week: [
    { id: 1, label: "today", value: 0 },
    { id: 2, label: "yesterday", value: 1 },
    { id: 3, label: "one week ago", value: 7 },
    { id: 4, label: "one month ago", value: 30 },
    { id: 5, label: "X days ago", value: "Input" },
    { id: 6, label: "select date", value: "Date" },
  ],
  date_range: [
    { id: 1, label: "the past week", value: 7 },
    { id: 2, label: "the past month", value: 30 },
    { id: 3, label: "select date range", value: "Date Range" },
  ],
  lead_status: [
    {
      id: 1,
      label: "New Lead",
      value: 1,
    },
    {
      id: 1,
      label: "Converted Lead",
      value: 2,
    },
    {
      id: 1,
      label: "No response",
      value: 3,
    },
    {
      id: 1,
      label: "Interested",
      value: 4,
    },
    {
      id: 1,
      label: "Not interested",
      value: 5,
    },
  ],
  lead_reasons: [
    {
      id: 0,
      label: "Interested in an offering",
      value: 0,
    },
    { label: "Payment Failed", id: 1, value: 4 },
    { label: "Payment Initiated", id: 2, value: 5 },
    {
      label: "Requested to be contacted",
      id: 3,
      value: 1,
    },
    { label: "Viewed an offering", id: 4, value: 2 },
    { label: "Viewed your website", id: 5, value: 3 },
    { label: "Imported leads", id: 6, value: 8 },
  ],
  booking_offering: {
    field: "offering_purchased",
    comparator: "exact",
    value_type: "listings",
  },
};

export const segment_dropdowns_customers = {
  country: {
    is: {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
    },
    "is not": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
    },
    "is empty": {
      type: enums_component_type.List,
      data: "boolean",
      value_type: "boolean",
    },
    "is any of": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
      multi: true,
    },
  },
  first_transaction_date: {
    is: {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is not": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is within": {
      type: enums_component_type.List,
      data: "date_range",
      value_type: "now_days",
    },
  },
  last_transaction_date: {
    is: {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is not": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is within": {
      type: enums_component_type.List,
      data: "date_range",
      value_type: "now_days",
    },
  },
  offering_purchased: {
    is: {
      type: enums_component_type.Offering,
      data: "offering",
      value_type: "listings",
    },
    "is not": {
      type: enums_component_type.Offering,
      data: "offering",
      value_type: "listings",
    },
    "is any of": {
      type: enums_component_type.Offering,
      data: "offering",
      value_type: "listings",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.Offering,
      data: "offering",
      value_type: "listings",
      multi: true,
    },
  },
  transactions_count: {
    "is equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is not equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is greater than": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is less than": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is greater than or equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is less than or equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
  },
  amount_spent: {
    "is equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is not equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is greater than": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is less than": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is greater than or equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
    "is less than or equal to": {
      type: enums_component_type.Input,
      value_type: "integer",
    },
  },
  feedback_rating: {
    "is equal to": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
    "is not equal to": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
    "is greater than": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
    "is less than": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
    "is greater than or equal to": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
    "is less than or equal to": {
      type: enums_component_type.Input,
      value_type: "feedback_filter",
      filter_type: "feedback_filter",
    },
  },
  coupon_applied: {
    "is applied to": {
      type: enums_component_type.List,
      data: "coupons",
      value_type: "advance_filter",
    },
  },
  time_offer_applied: {
    "is applied to": {
      type: enums_component_type.List,
      data: "bookings",
      value_type: "advance_filter",
    },
  },
  discount_applied: {
    "is applied to": {
      type: enums_component_type.List,
      data: "discounts",
      value_type: "advance_filter",
    },
  },
};

export const segment_dropdowns_leads = {
  country: {
    is: {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
    },
    "is not": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
    },
    "is empty": {
      type: enums_component_type.List,
      data: "boolean",
      value_type: "boolean",
    },
    "is any of": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.List,
      data: "countries",
      value_type: "country",
      multi: true,
    },
  },
  first_occurrence_time: {
    is: {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is not": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is within": {
      type: enums_component_type.List,
      data: "date_range",
      value_type: "now_days",
    },
  },
  updated_at: {
    is: {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is not": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is after": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is on or before": {
      type: enums_component_type.List,
      data: "date_value_week",
      value_type: "now_days",
    },
    "is within": {
      type: enums_component_type.List,
      data: "date_range",
      value_type: "now_days",
    },
  },
  status: {
    is: {
      type: enums_component_type.List,
      data: "lead_status",
      value_type: "lead_status",
    },
    "is not": {
      type: enums_component_type.List,
      data: "lead_status",
      value_type: "lead_status",
    },
    "is any of": {
      type: enums_component_type.List,
      data: "lead_status",
      value_type: "lead_status",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.List,
      data: "lead_status",
      value_type: "lead_status",
      multi: true,
    },
  },
  org_member: {
    is: {
      type: enums_component_type.List,
      data: "assignee",
      value_type: "org_member",
    },
    "is not": {
      type: enums_component_type.List,
      data: "assignee",
      value_type: "org_member",
    },
    "is empty": {
      type: enums_component_type.List,
      data: "boolean",
      value_type: "boolean",
    },
    "is any of": {
      type: enums_component_type.List,
      data: "assignee",
      value_type: "org_member",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.List,
      data: "assignee",
      value_type: "org_member",
      multi: true,
    },
  },
  reason_type: {
    is: {
      type: enums_component_type.List,
      data: "lead_reasons",
      value_type: "reason_type",
    },
    "is not": {
      type: enums_component_type.List,
      data: "lead_reasons",
      value_type: "reason_type",
    },
    "is empty": {
      type: enums_component_type.List,
      data: "boolean",
      value_type: "boolean",
    },
    "is any of": {
      type: enums_component_type.List,
      data: "lead_reasons",
      value_type: "reason_type",
      multi: true,
    },
    "is none of": {
      type: enums_component_type.List,
      data: "lead_reasons",
      value_type: "reason_type",
      multi: true,
    },
  },
};

export const addRadioOptions = [
  {
    label: `I'm segmenting for my Customers`,
    value: 1,
  },
  {
    label: `I'm segmenting for my Leads`,
    value: 2,
  },
];

export const SEGMENTS_LEARN_MORE_HREFS = {
  SEGMENTS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000379615-creating-segments-on-exly-and-making-the-most-of-it",
  SEGMENTS_VIDEO: "https://www.youtube-nocookie.com/embed/yrxVavXPm_0",
};
