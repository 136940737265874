import React from "react";
import useStyles from "../../AllSegments.style";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import cx from "classnames";

export const MobileTableSegmentConfigSecondary = ({
  record,
  audience,
  type,
}) => {
  const classes = useStyles();
  const hasSegmentationWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.SEGMENTATION
  );
  const hasWhatsappWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const hasEmailWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  const showWhatsapp = hasSegmentationWriteAccess && hasWhatsappWriteAccess;
  const showEmail = hasSegmentationWriteAccess && hasEmailWriteAccess;

  const history = useHistory();

  const redirectComposeEmails = (record) => {
    if (!showEmail) return;
    history.push(`/ComposeEmail/${record?.uuid}?type=${type}`);
  };

  const redirectComposeWhatsapp = (record) => {
    if (!showWhatsapp) return;
    history.push(
      `/whatsapp-broadcasts/select?segment_id=${record?.uuid}&type=${type}`
    );
  };

  return (
    <>
      <div
        className={cx(classes.whatsAppMob, !showWhatsapp && classes.disabled)}
      >
        <div className={classes.header}>
          <div className={classes.desc}>
            <span className={classes.boldTextMain}>
              {record?.stats
                ? record?.stats?.whatsapp_avg_open_rate + "%"
                : "0%"}
            </span>{" "}
            open rate
            <br />
            <span className={classes.secondText}>
              {record?.stats ? record?.stats?.whatsapp_total_recipients : "0"}
            </span>{" "}
            mesasges sent
          </div>
          <div className={classes.icon}>
            <WhatsAppIcon />
          </div>
        </div>
        <div
          className={`${classes.footer} ${
            audience > 0 ? null : classes.disabledOption
          }`}
          onClick={() => redirectComposeWhatsapp(record)}
        >
          Send Broadcast{" "}
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </div>
      </div>

      <div className={classes.mailMob}>
        <div className={classes.headerMail}>
          <div className={classes.descMail}>
            <span className={classes.boldTextMain}>
              {record?.stats ? record?.stats?.email_avg_open_rate + "%" : "0"}
            </span>{" "}
            open rate
            <br />
            <span className={classes.secondText}>
              {" "}
              {record?.stats ? record?.stats?.email_total_recipients : "0"}
            </span>{" "}
            emails sent
          </div>
          <div className={classes.iconMail}>
            <MailOutlineIcon />
          </div>
        </div>
        <div
          className={`${classes.footerMail} ${
            audience > 0 ? null : classes.disabledOption
          }`}
          onClick={() => redirectComposeEmails(record)}
        >
          Send Email <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </div>
      </div>
    </>
  );
};
