// Third party imports
import React, { useMemo } from "react";

import global_constants from "constants/constants";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

// Util functions and providers
import {
  getTableConfig,
  tabFilterConfig,
  filterConfig,
} from "./allSegementsTableConfig";

import ExlyTable from "../../../../common/Components/ExlyTable";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { useLocation } from "react-router";
import {
  ActionField,
  handleDeleteSegment,
  handleDuplicateSegment,
} from "./components/TableComponents/ActionField";
import { MobileTableSegmentConfigSecondary } from "./components/TableComponents/MobileTableSegmentConfigSecondary";
import constants, {
  enums_targets,
  SEGMENTS_LEARN_MORE_HREFS,
} from "./constants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useHistory } from "react-router-dom";
import QuickGuideButton from "ui/pages/Seo/components/QuickGuideButton/QuickGuideButton";

import { useToggleState } from "utils/hooks";
import GuideCarousel from "ui/pages/Seo/components/GuideCarousel/GuideCarousel";
import FeatureOnboarding from "common/Components/FeatureOnboarding/FeatureOnboarding";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import SectionIntroBrief from "ui/pages/Seo/components/SectionIntroBrief/SectionIntroBrief";
import SectionIntroList from "ui/pages/Seo/components/SectionIntroList/SectionIntroList";
import {
  getSegmentationGuideSlides,
  getSegmentationIntoSectionBrief,
  segmentationIntroSectionList,
  segmentLabels,
} from "./constants";

import style from "./NewSegment/NewSegment.module.css";

import CreateSegment from "./CreateSegment/CreateSegment";
import { addEditSegment } from "./apiUtils";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AddIcon from "@material-ui/icons/Add";
import ExlyModal from "common/Components/ExlyModal";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { useDispatch } from "react-redux";
import { EXLY_SEGMENT_RESET_SEGMENT } from "./redux/actions";
import { Header } from "common/Components/Header";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";

// Main Component
const AllSegments = (props) => {
  const location = useLocation();
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.SEGMENTS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const [isGuideModalOpen, openGuideModal, hideGuideModal] =
    useToggleState(false);

  const [
    isCreateSegmentModalOpen,
    openCreateSegmentModal,
    hideCreateSegmentModal,
  ] = useToggleState(false);

  const [target, setTarget] = React.useState(enums_targets.CUSTOMERS);

  const [showDynamicDuplicateModal, setShowDynamicDuplicateModal] =
    React.useState(false);

  const [primaryActionFields, setPrimaryActionFields] = React.useState({});

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [processing, setProcessing] = React.useState(false);

  const guideSlides = useMemo(() => {
    return getSegmentationGuideSlides(isDesktop);
  }, [isDesktop]);

  // quick guide button
  const quickGuideButton = (
    <QuickGuideButton
      openGuideModal={openGuideModal}
      isTooltipOpen={false}
      steps={[]}
    />
  );

  React.useEffect(() => {
    window.sessionStorage.setItem("fromRoute", "All Segments");
  }, []);

  const isLeadsTab = props.basePath.includes(constants.lead_url);

  const handleCreateSegment = () => {
    openCreateSegmentModal();
    dispatch({
      type: EXLY_SEGMENT_RESET_SEGMENT,
    });
  };

  const handleSetConditions = () => {
    addEditSegment(history, target);
  };

  const layoutProps = useMemo(
    () => ({
      paddingDesktop: "0",
      paddingBottom: "120px",
      layoutDesktopMargin: "20px 24px",
      noMobileBoxShadow: true,
      overflow: "hidden",
      learnMoreHref: SEGMENTS_LEARN_MORE_HREFS.SEGMENTS,
    }),
    [isDesktop]
  );

  const footerProps = useMemo(
    () => ({
      primarybtn: (
        <>
          <AddIcon className="mr-8" />
          {segmentLabels.create_segment}
        </>
      ),
      primaryClick: () => handleCreateSegment(),
      hidePrimarybtn: false,
    }),
    []
  );

  return (
    <div>
      {isDesktop && (
        <Header
          title={segmentLabels.page_title}
          learnMoreHref={SEGMENTS_LEARN_MORE_HREFS.SEGMENTS}
          about="about creating and managing your segments."
          right={
            <div className="d-flex align-items-center">
              {quickGuideButton}
              {
                <Button
                  className={classnames("ml-4")}
                  onClick={handleCreateSegment}
                >
                  {segmentLabels.create_segment}
                  {
                    <div className="ml-2">
                      <AddIcon />
                    </div>
                  }
                </Button>
              }
            </div>
          }
          classNames={{ container: style.headerContainer }}
          hideBackButton
        />
      )}

      <ExlyTable
        ra_props={{
          ...props,
        }}
        noExportButton
        tableTitle={
          isDesktop ? undefined : (
            <div className="d-flex align-items-center justify-content-between">
              <div>{segmentLabels.page_title}</div>
              {quickGuideButton}
            </div>
          )
        }
        selectedTab={location.pathname}
        columnConfig={getTableConfig(
          isLeadsTab,
          setShowDynamicDuplicateModal,
          setPrimaryActionFields,
          setShowDeleteModal
        )}
        tableFilters={filterConfig}
        tabConfig={tabFilterConfig}
        layoutProps={layoutProps}
        renderSecondaryAction={(record) => (
          <MobileTableSegmentConfigSecondary
            record={record}
            audience={
              isLeadsTab ? record?.stats?.leads : record?.stats?.customers
            }
            type={
              isLeadsTab
                ? global_constants.segments_target_type.leads
                : global_constants.segments_target_type.customers
            }
          />
        )}
        renderPrimaryColumnRightNode={(record) => {
          return (
            <>
              <ActionField
                record={record}
                type={isLeadsTab ? "leads" : "customers"}
                setShowDynamicDuplicateModal={setShowDynamicDuplicateModal}
                setPrimaryActionFields={setPrimaryActionFields}
                setShowDeleteModal={setShowDeleteModal}
              />
            </>
          );
        }}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        primaryKey="id"
        hasMobileFooter
        footerProps={footerProps}
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No segments yet",
          description:
            "As soon as we have a new segment, it will show up here. View the video to learn more.",
          videoSrc: SEGMENTS_LEARN_MORE_HREFS.SEGMENTS_VIDEO,
          playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
          primaryCtaProps: {
            title: "Create a Segment",
            onClick: handleCreateSegment,
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: layoutProps.learnMoreHref,
          },
          className: "mt-3",
        }}
      />

      {isGuideModalOpen && (
        <GuideCarousel
          open={isGuideModalOpen}
          onClose={hideGuideModal}
          slides={guideSlides}
        />
      )}
      <FeatureOnboarding
        featureKey={onboardingFeatureKeys.SEGMENTS.USER_SEGMENTATION}
        slides={[
          <SectionIntroBrief
            {...getSegmentationIntoSectionBrief(isDesktop)}
            key="one"
          />,
          <SectionIntroList {...segmentationIntroSectionList} key="two" />,
        ]}
      />
      {isCreateSegmentModalOpen && (
        <CreateSegment
          open={isCreateSegmentModalOpen}
          onClose={hideCreateSegmentModal}
          setTarget={setTarget}
          handleSetConditions={handleSetConditions}
        />
      )}

      {showDeleteModal && (
        <ExlyDeleteModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onDelete={() => {
            let { record, closePopover, notify, refresh } =
              primaryActionFields || {};
            handleDeleteSegment(
              record,
              closePopover,
              notify,
              refresh,
              processing,
              setProcessing
            );
            setShowDeleteModal(false);
          }}
          title="Confirm Delete"
          description="Are you sure you want to delete the segment?"
        />
      )}

      {showDynamicDuplicateModal && (
        <ExlyModal
          title="Dynamic Segment here!"
          open={showDynamicDuplicateModal}
          onClose={() => {
            setShowDynamicDuplicateModal(false);
          }}
          modal_props={{
            modalPaperClassName: style.modalPaperClassName,
          }}
          mobile_modal_props={{
            secondaryAction: {
              label: "Close",
              onClick: () => setShowDynamicDuplicateModal(false),
            },
          }}
          secondaryBtnText="Close"
          onSecondaryBtnClick={() => {
            setShowDynamicDuplicateModal(false);
          }}
          primaryBtnText="Duplicate anyway"
          onPrimaryBtnClick={() => {
            let { record, notify, history } = primaryActionFields || {};
            handleDuplicateSegment(record, notify, history);
            setShowDynamicDuplicateModal(false);
          }}
        >
          <div>
            <div className={style.formHeader}>
              You are about to duplicate a Dynamic Segment.
            </div>
            <div className={style.formContainer}>
              <ErrorOutlineIcon color="error" fontSize="small" />
              <div className={style.formInfo}>
                Please know, if you duplicate a dynamic segment, then the
                duplicated segment won&apos;t update its conditions
                automatically. It will become a normal segment.
              </div>
            </div>
          </div>
        </ExlyModal>
      )}
    </div>
  );
};

export default withComponentLibraryTheme(AllSegments);
