import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  mobile_listing_wrapper: {
    padding: "0 16px 16px 16px",
    borderBottom: "none",
    marginBottom: "112px",
  },
  desktop_actions: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  mobile_modal_paper: {
    maxHeight: "none",
    height: "100%",
  },
  desktop_modal_paper: {
    maxWidth: "unset",
    width: "80%",
    minHeight: "300px",
  },
  whatsAppMob: {
    background: "#F2FFFB",
    borderRadius: "5px",
    marginRight: "6px",
    width: "50%",
  },

  header: {
    display: "flex",
  },

  disabledOption: {
    opacity: "0.5 !important",
    pointerEvents: "none !important",
  },

  desc: {
    width: "100%",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.3)",
    paddingLeft: "12px",
    marginTop: "12px",
    marginBottom: "16px",
  },

  footer: {
    background: "#E2FFF6",
    height: "31px",
    marginTop: "0px",
    color: "#00A570",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    padding: "5px 12px 12px 12px",
    borderRadius: "0px 0px 5px 5px",
  },

  icon: {
    marginTop: "10px",
    marginRight: "10px",
    color: "#00A570",
  },

  mailMob: {
    background: "#F3F8FF",
    borderRadius: "5px",
    width: "50%",
    marginLeft: "5px",
    marginRight: "8px",
  },

  headerMail: {
    display: "flex",
  },

  descMail: {
    width: "100%",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.3)",
    paddingLeft: "12px",
    marginTop: "12px",
    marginBottom: "16px",
  },

  footerMail: {
    background: "#E3EEFC",
    height: "31px",
    marginTop: "0px",
    color: "#0052C9",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    padding: "5px 12px 12px 12px",
    borderRadius: "0px 0px 5px 5px",
  },
  iconMail: {
    position: "absolute",
    right: "18px",
    marginTop: "10px",
    color: "#0052C9",
  },
  arrowIcon: {
    marginBottom: "2px",
  },
  secondText: {
    fontWeight: 700,
  },

  boldTextMain: {
    color: "#272522",
    letterSpacing: "0.02em",
    fontWeight: 500,
    fontSize: "14px",
  },
});

export default useStyles;
