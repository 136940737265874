// Third party imports
import React from "react";

// Util functions and providers
import { getUserCurrencySymbol } from "../../../../utils/AuthUtil";
import { roundOff } from "../../../../utils/Utils";
import { is_empty } from "utils/validations";
import { MailBroadCastField } from "./components/TableComponents/MailBroadcastField";
import { DynamicSegmentsPopUp } from "./components/TableComponents/DynamisSegmentsPopup";
import { ActionField } from "./components/TableComponents/ActionField";
import { WhatsAppBroadCastField } from "./components/TableComponents/WhatsAppBroadcastField";
import { InfoIcon } from "./components/TableComponents/InfoIconTooltip";
import global_constants from "constants/constants";

const getValueLeadsOrCustomers = (value) => {
  if (is_empty(value)) {
    return 0;
  } else {
    return value;
  }
};

export const getTableConfig = (
  isLeadsTab,
  setShowDynamicDuplicateModal,
  setPrimaryActionFields,
  setShowDeleteModal
) => {
  let tableConfig = [
    {
      field: "title",
      width: 0,
      isPrimary: true,
      sortable: false,
      hiddenDesktopColumn: false,
      columnClassName: "word_break",
      valueFormatter: (record) => (
        <>
          {record?.is_dynamic || record?.is_tag_embedded ? (
            <DynamicSegmentsPopUp record={record} />
          ) : null}
        </>
      ),
    },
    {
      field: "title",
      headerName: "Segment Name",
      isPrimary: true,
      sortable: false,
      width: "250px",
      hiddenDesktopColumn: false,
      // columnClassName: "word_break",
      valueFormatter: (record) => (
        <>
          <InfoIcon record={record} />
        </>
      ),
      ellipsis: true,
      maxCharCount: 25,
    },
    {
      field: "title",
      headerName: "Segment Name",
      isPrimary: true,
      sortable: false,
      hiddenDesktopColumn: true,
      columnClassName: "word_break",
      renderColumn: (record) => (
        <>
          {record?.is_dynamic === true ? (
            <DynamicSegmentsPopUp record={record} />
          ) : (
            <InfoIcon record={record} />
          )}
        </>
      ),
      ellipsis: true,
      maxCharCount: 25,
    },
    //Displaying Customers if the tab which is selected is Leads || Displaying Customers Otherwise
    {
      field: "customers",
      headerName: `${isLeadsTab ? "Leads" : "Customers"} `,
      valueFormatter: (record) =>
        isLeadsTab
          ? getValueLeadsOrCustomers(record?.stats?.leads)
          : getValueLeadsOrCustomers(record?.stats?.customers),
      sortable: false,
      columnClassName: "word_break",
    },

    {
      field: "stats",
      headerName: "Bookings",
      valueFormatter: (record) =>
        record?.stats?.bookings || record?.stats?.bookings === 0
          ? record?.stats?.bookings
          : "0",
      sortable: false,
      hiddenDesktopColumn: isLeadsTab ? true : false,
      hidden: isLeadsTab ? true : false,
    },

    {
      field: "stats",
      headerName: "Revenue",
      valueFormatter: (record) =>
        record?.stats?.revenue || record?.stats?.revenue === 0
          ? `${getUserCurrencySymbol()} ${roundOff(record?.stats?.revenue, 0)}`
          : "0",
      noWrap: true,
      sortable: false,
      hiddenDesktopColumn: isLeadsTab ? true : false,
      hidden: isLeadsTab ? true : false,
    },
    {
      field: "stats",
      headerName: "Total whatsapp messages sent",
      valueFormatter: (record) =>
        record?.stats?.whatsapp_total_recipients ||
        record?.stats?.whatsapp_total_recipients === 0
          ? record?.stats?.whatsapp_total_recipients
          : "0",
      width: "120px",

      sortable: false,
      hidden: true,
      hiddenDesktopColumn: false,
      columnClassName: "word_break",
    },
    {
      field: "avg_open_rate_wa",
      headerName: "Avg open rate (WhatsApp)",
      hidden: true,
      hiddenDesktopColumn: false,
      valueFormatter: (record) =>
        record?.stats?.whatsapp_avg_open_rate ||
        record?.stats?.whatsapp_avg_open_rate === 0
          ? record?.stats?.whatsapp_avg_open_rate
          : "0",
      sortable: false,
      width: "120px",

      columnClassName: "word_break",
    },
    {
      field: "stats",
      headerName: "Total emails sent",
      valueFormatter: (record) =>
        record?.stats?.email_total_recipients ||
        record?.stats?.email_total_recipients === 0
          ? record?.stats?.email_total_recipients
          : "0",
      sortable: false,
      hidden: true,
      hiddenDesktopColumn: false,
      width: "80px",

      columnClassName: "word_break",
    },
    {
      field: "avg_open_rate_email",
      hidden: true,
      hiddenDesktopColumn: false,

      headerName: "Avg open rate (Email)",
      valueFormatter: (record) =>
        record?.stats?.email_avg_open_rate ||
        record?.stats?.email_avg_open_rate === 0
          ? record?.stats?.email_avg_open_rate
          : "0",
      sortable: false,
      width: "120px",

      columnClassName: "word_break",
    },
    {
      field: "",
      headerName: "Broadcast",
      valueFormatter: (record) => (
        <>
          <WhatsAppBroadCastField
            record={record}
            label={isLeadsTab ? "leads" : "customers"}
            audience={
              isLeadsTab ? record?.stats?.leads : record?.stats?.customers
            }
            type={
              isLeadsTab
                ? global_constants.segments_target_type.leads
                : global_constants.segments_target_type.customers
            }
          />
        </>
      ),
      sortable: false,
      noWrap: true,
      hidden: true,
      width: "50px",
      fixed: "right",
    },
    {
      field: "",
      headerName: "Email",
      valueFormatter: (record) => (
        <>
          <MailBroadCastField
            record={record}
            label={isLeadsTab ? "leads" : "customers"}
            audience={
              isLeadsTab ? record?.stats?.leads : record?.stats?.customers
            }
            type={
              isLeadsTab
                ? global_constants.segments_target_type.leads
                : global_constants.segments_target_type.customers
            }
          />
        </>
      ),
      sortable: false,
      noWrap: false,
      hidden: true,
      width: "50px",
      fixed: "right",
    },
    {
      field: "",
      headerName: "Action",
      valueFormatter: (record) => (
        <ActionField
          record={record}
          type={isLeadsTab ? "leads" : "customers"}
          setShowDynamicDuplicateModal={setShowDynamicDuplicateModal}
          setPrimaryActionFields={setPrimaryActionFields}
          setShowDeleteModal={setShowDeleteModal}
        />
      ),
      sortable: false,
      noWrap: true,
      hidden: true,
      width: "50px",
      fixed: "right",
    },
  ];
  return tableConfig;
};

export const filterConfig = [
  {
    source: "title",
    placeholder: "Name",
    type: "input",
    disableContains: false,
  },
];

export const addOptionsRadio = [
  {
    label: `I'm segmenting for my Customers`,
    value: "segmenting_customers",
  },
  {
    label: `I'm segmenting for my Leads`,
    value: "segmenting_customers",
  },
];

export const tabFilterConfig = [
  {
    label: "Customers",
    value: "/host/segments/list/customers",
    path: "/host/segments/list/customers",
  },
  {
    label: "Leads",
    value: "/host/segments/list/leads",
    path: "/host/segments/list/leads",
  },
];
