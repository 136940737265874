import { app_route_ids, app_route_keys } from "constants/urlPaths";
import constants, {
  advance_filter_value,
  enums_targets,
  segment_data,
} from "./constants";
import { is_empty } from "utils/validations";
import moment from "moment";

export const addEditSegment = (history, target_value) => {
  history.push(
    `${
      app_route_ids[app_route_keys.create_segment_conditions]
    }?target=${target_value}`
  );
};

export const getAssigneeList = async (dataProvider, api) => {
  try {
    const response = await dataProvider.custom_request(
      api.get_assignee_list,
      "GET",
      { page: "all" }
    );
    return response;
  } catch (error) {
    // Handle error here
    console.error("getAssigneeList>>>", error);
  }
};

export const updateSegment = async (dataProvider, api, finalID, payload) => {
  try {
    const { data, status, message } = await dataProvider.custom_request(
      api.segments.update_segments + "/" + finalID,
      "POST",
      payload
    );
    return { data, status, message };
  } catch (error) {
    // Handle error here
    console.error("updateSegment>>>", error);
    return {
      status: error?.status,
      message: error?.message,
    };
  }
};

export const createSegment = async (dataProvider, api, payload) => {
  try {
    const { data, status, message } = await dataProvider.custom_request(
      api.segments.create_segments,
      "POST",
      payload
    );
    return { data, status, message };
  } catch (error) {
    // Handle error here
    console.error("🚀 createSegment>>>", error, error.status, error.message);
    return {
      status: error?.status,
      message: error?.message,
    };
  }
};

export const duplicateSegment = async (dataProvider, api, record) => {
  try {
    const data = await dataProvider.custom_request(
      api.segments.duplicate_segment + "/" + record?.uuid,
      "POST"
    );
    return data;
  } catch (error) {
    // Handle error here
    console.error("duplicateSegment>>>", error);
  }
};

export const deleteSegment = async (dataProvider, api, record) => {
  try {
    const data = await dataProvider.custom_request(
      api.segments.delete_segment + "/" + record?.uuid,
      "POST"
    );
    return data;
  } catch (error) {
    // Handle error here
    console.error("deleteSegment>>>", error);
  }
};

export const getSegmentCustomer = async ({
  finalID,
  draftID,
  target,
  api,
  dataProvider,
  page,
}) => {
  try {
    const final_ID = finalID || draftID;
    const finalURL =
      target == enums_targets.LEADS
        ? api.segments.get_segment_people_leads
        : api.segments.get_segment_people_customer;
    const data = await dataProvider.custom_request(finalURL, "GET", {
      segment_uid: final_ID,
      page: page,
    });

    return data;
  } catch (error) {
    // Handle error here
    console.error("getSegmentCustomer>>>", error);
  }
};

{
  /*
targetBaseTitle function checks if segment is for "Customer" or "Leads" based on
"target value" 
*/
}
export const targetBaseTitle = (target) => {
  return `${target == enums_targets.LEADS ? "leads" : "customers"}`;
};

export const getConditionValues = ({ data, offeringsList }) => {
  let booking = "";
  let feedback = "";
  let bookingData = [];
  let valueTitle = "";

  if (data?.value_type === constants.advance_filter) {
    switch (data?.field) {
      case constants.coupon_applied:
        bookingData = segment_data.coupons;
        break;
      case constants.time_offer_applied:
        bookingData = segment_data.bookings;
        break;
      default:
        bookingData = segment_data.discounts;
    }

    // sub_filter is an array with one default value, but in case of offering select one more value is appended.
    if (data?.sub_filter[0]?.value_type === constants.offering_data_type) {
      booking =
        offeringsList?.find((i) => i.uuid === data?.offering)?.title ||
        data?.offering;
    } else {
      booking = bookingData?.find(
        (booking) => booking.value && booking.value === data.sub_filter[0].label
      );

      booking = booking?.label;
    }
  }

  // sub_filter is an array with one default value, but in case of offering select one more value is appended.
  if (data?.filter_type === constants.feedback_filter) {
    if (data?.sub_filter[0]?.value_type === constants.offering_data_type) {
      feedback =
        offeringsList?.find((i) => i.uuid === data?.offering)?.title ||
        data?.offering;
    } else {
      feedback = segment_data.feedbacks.find(
        (feedback) =>
          feedback.value && feedback.value === data.sub_filter[0].label
      );

      feedback = feedback?.label;
    }
  }

  if (Array.isArray(data?.valueTitle) && is_empty(data?.valueTitle)) {
    valueTitle = data?.value;
  } else {
    valueTitle = data?.valueTitle || data?.value;
  }

  // @dev - handling valueTitle to have offering titles instead of uuids when data does not itself have valueTitle.
  if (
    data?.value_type === constants.offering_data_type &&
    is_empty(data?.valueTitle)
  ) {
    if (Array.isArray(valueTitle)) {
      valueTitle = valueTitle?.map(
        (i) => offeringsList?.find((j) => j.uuid === i)?.title
      );
    } else {
      valueTitle = offeringsList?.find((j) => j.uuid === valueTitle)?.title;
    }
  }

  // If value is date or date range: format should be dd-mm-yyyy to show
  if (
    data?.value_type === constants.condition_value_types.date_range ||
    data?.value_type === constants.condition_value_types.date
  ) {
    let formatedValueTitle = Array.isArray(valueTitle)
      ? valueTitle
      : [valueTitle];

    valueTitle = formatedValueTitle.map((item) => {
      return moment(item, "YYYY-MM-DD").format("DD-MM-YYYY");
    });
  }

  // in case of lead status and reason type show enum instead of value (New Lead instead of 1)
  if (
    data?.value_type === constants.lead_status ||
    data?.value_type === constants.reason_type
  ) {
    let formatedValueTitle = Array.isArray(valueTitle)
      ? valueTitle
      : [valueTitle];

    valueTitle = formatedValueTitle.map((item) => {
      return advance_filter_value[data?.field][item]?.label;
    });
  }

  if (Array.isArray(valueTitle)) {
    const comparator =
      constants.value_based_comparator[data?.value_type] || ", ";
    valueTitle = valueTitle.join(comparator);
  }

  return { booking, feedback, valueTitle };
};
