import React from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import cx from "classnames";
import classes from "./TableFields.module.css";
import { Tooltip } from "@my-scoot/component-library-legacy";
import { useHistory } from "react-router";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";

export const MailBroadCastField = ({
  onClick = () => {},
  label,
  record,
  audience,
  type,
}) => {
  const history = useHistory();
  const hasSegmentationWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.SEGMENTATION
  );
  const hasEmailWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const disabled = !(hasSegmentationWriteAccess && hasEmailWriteAccess);

  return (
    <div onClick={() => onClick({ record })}>
      {audience > 0 ? (
        <MailOutlineIcon
          className={cx(
            classes.MailOutlineIcon,
            disabled && classes.disabledIconMail
          )}
          onClick={() => {
            if (disabled) return;
            history.push(`/ComposeEmail/${record?.uuid}?type=${type}`);
          }}
        />
      ) : (
        <>
          <Tooltip
            color="secondary"
            title={
              <div className={classes.tooltipContainer}>
                <div>No {label} to send emails</div>
              </div>
            }
          >
            <MailOutlineIcon className={classes.disabledIconMail} />
          </Tooltip>
        </>
      )}
    </div>
  );
};
