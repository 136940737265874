import React from "react";
import classes from "./TableFields.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExlyPopover from "common/Components/ExlyPopover";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DynamicSegmentsPopUp } from "./DynamisSegmentsPopup";
import constants from "../../constants";
import { appendUrlParams } from "utils/urlUtils";
import { api, dataProvider } from "data";
import { useRefresh } from "react-admin";
import { useNotifications } from "utils/hooks";
import { deleteSegment } from "../../apiUtils";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
} from "constants/urlPaths";
import { useHistory } from "react-router-dom";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";

const AnchorComponent = ({ openPopover }) => {
  const isDesktop = useDesktopMediaQuery();
  const commonProps = {
    className: classes.ActionField,
  };
  return (
    <span className="pointer" onClick={openPopover}>
      {isDesktop ? (
        <MoreHorizIcon {...commonProps} />
      ) : (
        <MoreVertIcon {...commonProps} />
      )}
    </span>
  );
};

export const handleDuplicateSegment = async (record, notify, history) => {
  try {
    history.push(
      `${app_route_ids[app_route_keys.create_segment_conditions]}/${
        record?.uuid
      }?action=duplicate`
    );
  } catch (error) {
    notify("Some error occured!", {
      variant: "outlined",
      color: "coral_red",
    });
  }
};

export const handleDeleteSegment = async (
  record,
  closePopover,
  notify,
  refresh,
  processing,
  setProcessing
) => {
  try {
    if (processing) return;
    setProcessing(true);
    const data = await deleteSegment(dataProvider, api, record);
    if (data.status === 200) {
      notify("Segment deleted successfully!", "success");
      refresh();
      closePopover && closePopover();
    }
  } catch (error) {
    notify("Some error occured!", {
      variant: "outlined",
      color: "coral_red",
    });
  } finally {
    setProcessing(false);
  }
};

export const ActionField = ({
  record,
  type,
  setShowDynamicDuplicateModal,
  setPrimaryActionFields,
  setShowDeleteModal,
}) => {
  const refresh = useRefresh();
  const { notify } = useNotifications();
  const history = useHistory();
  const isDesktop = useDesktopMediaQuery();

  const [showActionDrawer, setShowActionDrawer] = React.useState(false);

  const is_editable = !(
    record.is_dynamic ||
    record.is_default ||
    record.is_tag_embedded
  );

  const PopoverComponent = ({ closePopover }) => (
    <div className={classes.MenuOptions}>
      {record.is_dynamic ? (
        <DynamicSegmentsPopUp action_type={constants.action_type_popover} />
      ) : null}
      <div
        className={classes.MenuItems + " " + classes.MenuItemViewEdit}
        onClick={() =>
          history.push(
            app_pathnames[app_route_keys.view_conditions]({
              segment_id: record?.uuid,
              segmentName: record.title,
              type,
            })
          )
        }
      >
        {is_editable ? "View and edit conditions" : "View conditions"}
      </div>
      <div
        className={classes.MenuItems}
        onClick={() =>
          history.push(
            app_pathnames[app_route_keys.communication_sent]({
              segment_id: record?.uuid,
              segmentName: record.title,
              type,
            })
          )
        }
      >
        Communications Sent
      </div>

      <div
        className={classes.MenuItems}
        onClick={() => {
          if (record.is_dynamic) {
            setShowDynamicDuplicateModal(true);
            closePopover();
            setPrimaryActionFields({
              record,
              notify,
              history,
            });
            return;
          }
          handleDuplicateSegment(record, notify, history);
        }}
      >
        Duplicate segment
      </div>
      {is_editable ? (
        <div
          className={classes.MenuItems + " " + classes.MenuItemDelete}
          onClick={() => {
            setShowDeleteModal(true);
            closePopover();
            setPrimaryActionFields({ record, closePopover, notify, refresh });
          }}
        >
          Delete segment
        </div>
      ) : null}
    </div>
  );

  let actions = [
    {
      icon: <VisibilityIcon className={classes.blueAction} />,
      onClick: () => {
        window.open(
          appendUrlParams(
            `${window.location.origin}/#/segments/conditions/${record?.uuid}`,
            { segment_name: record.title, type }
          ),
          "_self"
        );
      },
      label: `${is_editable ? "View and edit conditions" : "View conditions"}`,
      className: classes.blueButton,
    },
    {
      icon: <ChatBubbleOutlineOutlinedIcon className={classes.actionIcon} />,
      onClick: () => {
        history.push(
          app_pathnames[app_route_keys.communication_sent]({
            segment_id: record?.uuid,
            segmentName: record.title,
            type,
          })
        );
      },
      label: "Communications Sent",
      className: classes.blackButton,
    },
    {
      icon: <FileCopyOutlined className={classes.actionIcon} />,
      onClick: () => {
        if (record.is_dynamic) {
          setShowDynamicDuplicateModal(true);
          setPrimaryActionFields({
            record,
            notify,
            history,
          });
          return;
        }
        handleDuplicateSegment(record, notify, history);
      },
      label: "Duplicate segment",
      className: classes.blackButton,
    },
  ];

  if (is_editable) {
    actions = [
      ...actions,
      {
        icon: <DeleteRoundedIcon className={classes.redAction} />,
        onClick: () => {
          setShowDeleteModal(true);
          setShowActionDrawer(false);
          setPrimaryActionFields({ record, notify, refresh });
        },
        label: "Delete segment",
        className: classes.redButton,
      },
    ];
  }

  return (
    <div>
      {isDesktop ? (
        <ExlyPopover
          AnchorComponent={AnchorComponent}
          PopoverComponent={PopoverComponent}
          onClose={() => {}}
          forceDesktopDesign
        />
      ) : (
        <>
          <span
            className="pointer"
            onClick={() => {
              setShowActionDrawer(true);
            }}
          >
            <MoreVertIcon className={classes.ActionField} />
          </span>

          <ActionDrawer
            open={showActionDrawer}
            onClose={() => {
              setShowActionDrawer(false);
            }}
            actions={actions}
          />
        </>
      )}
    </div>
  );
};
