// Third party imports
import React from "react";
import classes from "./TableFields.module.css";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router";
import cx from "classnames";

import { Tooltip } from "@my-scoot/component-library-legacy";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";

export const WhatsAppBroadCastField = ({
  onClick = () => {},
  label,
  record,
  type,
  audience,
}) => {
  const history = useHistory();
  const hasSegmentationWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.SEGMENTATION
  );
  const hasWhatsappWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const disabled = !(hasSegmentationWriteAccess && hasWhatsappWriteAccess);

  return (
    <div onClick={() => onClick({ record })}>
      {audience > 0 ? (
        <WhatsAppIcon
          className={cx(
            classes.WhatsAppBroadCastField,
            disabled && classes.disabledIcon
          )}
          onClick={() => {
            if (disabled) return;
            history.push(
              `/whatsapp-broadcasts/select?segment_id=${record?.uuid}&type=${type}`
            );
          }}
        />
      ) : (
        <>
          <Tooltip
            color="secondary"
            title={
              <div className={classes.tooltipContainer}>
                <div>No {label} to broadcast.</div>
              </div>
            }
          >
            <WhatsAppIcon className={classes.disabledIcon} />
          </Tooltip>
        </>
      )}
    </div>
  );
};
